.contentPortfolio .simplebar-track.simplebar-vertical {
  right: 7px !important;
}

.contentPortfolio .builderToggleGroup {
  font-size: 13px;
}
.submitBasketCalendar {
  margin-left: 4%;
}

.submitBasketCalendar .CalendarDate div:nth-child(1) {
  color: #ffffff !important;
  font-weight: 400 !important;
  font-size: 12px;
  margin-bottom: 0px;
  margin-top: 0px;
}
.content-tabs-bulksbasket .react-tabs__tab {
  border-radius: 5px !important;
  margin: 5px;
  margin-bottom: 10px;
  font-weight: bold;
}

.content-tabs-bulksbasket .bitaTabs table td:nth-child(1) {
  background: transparent !important;
  width: 120px;
  font-weight: 400;
}

.content-tabs-bulksbasket .bitaTabs table td:nth-child(2) {
  background: transparent !important;
}

.content-tabs-bulksbasket .bitaTabs table td,
.content-tabs-bulksbasket .bitaTabs table tr {
  border: none;
}

.content-tabs-bulksbasket .bitaTabs table td,
.content-tabs-bulksbasket .bitaTabs table th {
  width: 80px;
  text-align: center;
}

.contentTableBasketPreview .content-Widget {
  padding: 0px !important;
}

.headerTable {
  border-bottom: 0.5px solid hsla(0, 0%, 100%, 0.4);
  padding: 10px 0;
  margin-bottom: 10px;
}

.contentTableBasketPreview th,
.tableUpdateBasket th {
  position: sticky;
  background: #3b3f44;
  z-index: 9999999;
}

.contentTableBasketPreview td {
  max-width: 25%;
  padding-right: 5px;
}
.needValidationSelect .react-datepicker-input {
  border: 1px solid #c93a3a !important;
}

.needValidation {
  border: 1px solid #c93a3a !important;
}

.contentTableBasketPreview h1 {
  font-weight: 500;
  font-size: 16px;
  margin-top: 20px;
}
.titleInfo {
  margin-right: 35px;
  margin-top: 20px;
}
.custom-row-container {
  position: relative;
}

.icon {
  padding-right: 20px;
  padding-top: 8px;
}
.info-icon-live {
  padding-right: 20px;
  text-align: right;
  display: flex;
  justify-content: flex-end;
}

.info-icon-live {
  padding-right: 20px;
  text-align: right;
  display: flex;
  justify-content: flex-end;
}

.icon-info {
  font-size: 20px;
  color: rgb(46, 235, 46);
}

.icon-error {
  font-size: 20px;
  color: yellow;
}

.tooltip-icon {
  margin-right: 5px;
  font-size: 20px;
}

.basket-table .tooltip {
  display: none;
  position: absolute;
  width: 280px;
  border-radius: 5px;
  text-wrap: wrap;
  background-color: #f7f7f7;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.4);
  padding: 3px 8px;
  color: #333333;
  transform: translateX(-210px);
  z-index: 999999999;
}
.info-icon-live:hover ~ .tooltip {
  left: -240px;
}

.tooltip .icon-error {
  color: red;
}
.tooltip .icon-info {
  color: green;
}

.info-icon-live:hover ~ .tooltip,
.info-icon-live:hover > .tooltip {
  display: flex;
  color: #333333;
  background: #f7f7f7;
  opacity: 0.85;
}

.basket-table table {
  border-spacing: 40px;
}

.basket-table .dropdown__control {
  max-height: 25px !important;
  min-height: 25px !important;
}

.basket-table .dropdown__control > div {
  max-height: 25px !important;
  min-height: 25px !important;
}

.basket-table .table-basket-shares input {
  border: 1px solid white;
  background: #4b5158 !important;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: text;
  color: white;
  font: inherit;
  width: 120px;
}
.basket-table th button {
  text-transform: uppercase;
  font-weight: bold;
}

.basket-table .table-basket-shares input:focus {
  border: 1px solid white;
  background: #4b5158 !important;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: text;
  color: white;
  font: inherit;
}

.basket-table thead,
.basket-table tfoot {
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 15px;
  padding-bottom: 10px;
  text-transform: uppercase;
}

/* .basket-table tbody .table-basket-company_name {
  width: 100%;
  max-width: 240px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
} */

.basket-table tbody .table-basket-company_name > div {
  width: 100%;
  white-space: nowrap;
  /* overflow: hidden; */
  text-overflow: ellipsis;
}

.basket-table tbody td svg {
  margin: auto;
}

.basket-table .newRow {
  border: 1px solid white;
  background: #4b5158 !important;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: text;
  color: #000;
  font: inherit;
}

.basket-table td {
  padding: 5px 20px;
}

.basket-table td:nth-child(2) {
  padding-left: 0px;
}

.table-basket-info {
  min-width: 100px !important;
  box-sizing: border-box;
}
.table-basket-constituents {
  font-weight: bold;
}

.table-basket-company_name {
  min-width: 180px;
}

.table-basket-shares {
  width: 120px;
}

.table-basket-iwf,
.table-basket-awf {
  width: 70px;
  font-weight: bold;
}

.basket-table td:nth-child(1) svg {
  width: auto !important;
  height: auto !important;
}
.addModalInstrument {
  min-height: 350px;
}

.addModalInstrument input {
  border: 1px solid white;
  /* background: #4b5158 !important; */
  padding: 4px 8px;
  border-radius: 4px;
  cursor: text;
  color: white;
  font: inherit;
  width: 100%;
}
.contentInputsAddInstrument > div {
  margin-bottom: 15px;
}
.inputIdentifier {
  margin-left: 0px;
  margin-bottom: 20px;
}

.previewSubmitBasket .simplebar-track.simplebar-vertical,
.previewUpdateBasket .simplebar-track.simplebar-vertical {
  top: 0;
  right: 0px;
  width: 11px;
}

.tooltipErrorApply {
  display: none;
  position: fixed;
  bottom: 100px;
  right: 50px;
  border-radius: 5px;
  text-wrap: wrap;
  background-color: #333333;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.4);
  padding: 3px 8px;
  color: #f7f7f7;
  z-index: 999999999;
}

.contentButtonApply:hover > .tooltipErrorApply {
  display: block;
  background: #333333;
  color: #f7f7f7;
}
