.pageContainer {
  border-radius: 8px;
  background: #2a2d31;
  height: 100%;
  padding: 20px;
}

.defaultPageTitle {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1.4px;
  margin: 0;
  display: flex;
  align-items: center;
}

.defaultPageTitle svg {
  margin-right: 15px;
}

.defaultPageTitle span {
  background: #fff;
  color: #0039cb;
  border-radius: 6px;
  padding: 4px 6px;
  margin-right: 4px;
  display: inline-block;
  font-weight: bold;
  height: auto;
}

.pageContainer button {
  /* flex-grow: 1; */
}

.optimizationStep {
  border-radius: 5px;
  height: 100%;
  background: #3b3f44;
  margin: 20px;
  padding-bottom: 150px;
}

.stepTitle {
  padding: 21px 15px;
  display: flex;
  align-items: center;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 2.42px;
  text-align: justify;
}

.optimizationStepHeader {
  display: flex;
  justify-content: space-between;
}

.stepSubtitle {
  padding: 0 15px;
  font-size: 15px;
  letter-spacing: 1.71px;
}

.stepNavigation {
  margin-top: 20px;
}

.stepNavigationButton {
  width: 120px !important;
  height: 35px;
  margin-right: 20px;
  visibility: hidden;
}

.stepNavigationButtonVisible {
  visibility: visible !important;
}

.stepTitle svg {
  border: 1px solid #fff;
  padding: 1px;
  border-radius: 3px;
  width: 17px;
  height: 17px;
}

.stepTitle span {
  margin-left: 15px;
}

/* Optimization component type card */
.cardOptimizationComponentContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.optimizationCard {
  margin: 30px 30px 0 0 !important;
  cursor: pointer;
  .title {
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 2.42px;
    line-height: 19px;
    text-align: justify;
  }
}

.optimizationSwitchComponentContainer {
  display: flex;
  position: relative;
  flex-flow: column;
  background: #3b3f44 !important;
  padding: 18px;
  border-radius: 4.05px;
  margin-top: 5px;
  width: 100%;
}

.optimizationTitleComponentContainer {
  display: flex;
  position: relative;
  flex-flow: column;
  background: #3b3f44 !important;
  padding: 18px;
  border-radius: 8px;
  margin-top: 36px;
  width: 100%;
  font-size: 30px;
  h2 {
    display: flex;
    align-items: center;
    font-size: 16px !important;
    span {
      margin-left: 10px;
    }
  }
  svg {
    width: 19px !important;
    height: 17px !important;
  }
}

.optimizationSwitchContent {
  padding: 5px 10px 20px 0;
  display: inline-block;
  min-width: 12%;
  /*
  min-height: 15px;
  padding: 10px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  */
}

.optimizationWidgetTitle {
  width: 50%;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 2.42px;
  line-height: 19px;
  text-align: justify;
  text-transform: uppercase;
}

.widgetDescription {
  width: 100%;
  font-size: 13px;
  letter-spacing: 1.48px;
  line-height: 19px;
  text-align: justify;
  margin-top: 5px;
  padding-top: 10px;
  border-top: 1px solid hsla(0, 0%, 100%, 0.4);
}

.optimizationWidgetHeaderContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;
}

.optimizationSwitchComponentTop {
  width: 250px;
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
}

.addibleRow {
  display: flex;
  align-items: center;
}

.addibleRow > div:first-child {
  padding-left: 0 !important;
}

.addibleRowRemoveButton {
  width: 25px !important;
  height: 25px !important;
  background: #51565c;
  color: #2962ff;
  border: 0;
}

.addibleRowPlusButton {
  width: 31px !important;
  height: 31px !important;
  background: #fff;
  color: #2962ff;
  border: 0;
  font-weight: 700;
}

.optimizationInputComponentContainer {
  display: flex;
  margin-right: 15px;
  width: 250px;
  justify-content: space-between;
}

.optimizationInputContainer {
  margin-left: 15px;
  display: flex;
}

.optimizedSelect {
  width: 100% !important;
}

.applyDeleteContainer {
  text-align: right;
  padding-top: 10px;
}

.applyDeleteButton {
  margin-right: 10px;
}

.dropdownPostText {
  display: inline-block;
  padding: 0 6px;
}

.dropdownLine {
  display: inline-block;
}

.dropdownContainer {
  display: inline-block;
  margin-right: 6px;
}

.dropdownTitle {
  display: block;
  padding: 6px 0;
  padding-top: 0px;
}

.variablesListRow {
  display: flex;
  min-width: 400px;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
}

.variablesListRow div {
  padding: 4px 20px;
  display: flex;
  align-items: center;
}

.variablesListRow div:last-child div {
  display: inline-block;
  width: 50%;
  overflow: hidden;
}

/* apply */
.subTitle {
  color: #2962ff;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
  font-size: 11px;
  margin: 14px 0;
}
.applyWidgetActions {
  display: flex;
  justify-content: space-between;
}
.applyWidgetApplyActionButton {
  padding: 12px 26px;
  width: auto !important;
  height: auto !important;
}
.applyWidgetTitle {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  svg {
    margin-right: 5px;
  }
}
.button {
  width: auto !important;
  height: auto !important;
  padding: 6px;
  margin: 0 4px 4px 4px;
}

/* tabs */
.tabsComponentContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  flex: 1;
}

.tabsContainer {
  display: flex;
  justify-content: space-between;
  flex: 1;
  width: 100%;
  flex-grow: 0;
}

.tabsButtonContainer {
  display: flex;
}

.tabContentContainer {
  width: 100%;
  height: calc(100% - 120px);
  flex: 1;
}

.tabTitleButton {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 11px;
  padding: 8px 10px;
  letter-spacing: 1.48px;
  text-align: center;
  border-radius: 3.15px;
  flex: 0 0;
}

.tabTitleButtonActive {
  background: #2962ff;
}
.tabTitleButtonInactive {
  border-radius: 4.05px;
  background-color: #4b5158;
}

/* popup */
.pupUpTitleContainer {
  display: flex;
  border-bottom: 1px solid #eee;
  align-items: center;
  padding: 10px 0;
}
.popUpTitle {
  margin: 0;
  padding: 0;
  font-size: 22px;
  text-transform: uppercase;
}
.popUpText {
  margin: 20px 0;
}
.popUpContent button {
  padding: 10px 30px;
  display: block;
  margin: auto;
}
.popUpContent .optimizationInputComponentContainer {
  display: block;
  width: 100%;
  margin: 8px 0;
}
.popUpAvantiIcon {
  margin-right: 20px;
}
.popUpCloser {
  position: absolute;
  font-size: 30px;
  font-weight: bold;
  top: -10px;
  right: 0;
  font-size: 26px;
  cursor: pointer;
  padding: 20px;
  box-sizing: border-box;
}
.popUpContent .optimizationInputTitle {
  display: block;
  color: #336cff;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
}
.widgetInput {
  background-color: unset;
  background: #4b5158;
  border-radius: 2.55px;
  font-size: 13px;
  width: 100px;
  height: 35px;
  line-height: 25px;
  overflow: hidden;
}
.widgetInput ~ label {
  top: 8px;
}
.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.inputfile + label {
  cursor: pointer;
  outline: none !important;
  box-shadow: none !important;
  outline-style: none !important;
  background-color: #fff;
  color: #2962ff;
  border-radius: 2.55px;
  font-size: 12px;
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
  letter-spacing: 1.37px;
  font-weight: 500;
  width: auto !important;
  height: auto !important;
  padding: 6px;
  margin: 0 4px 4px 4px;
}

.inputfile:focus + label,
.inputfile + label:hover {
  background-image: linear-gradient(90deg, #ffffff 0%, #d5d5d5 97%, #d4d4d4 100%);
  border-radius: 3.15px;
}
.popUpContent .optimizationInputComponentContainer div,
.popUpContent .optimizationInputComponentContainer input {
  width: 100%;
}

.popUpContent .optimizationInputContainer {
  margin-left: 0;
}

.brandingContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  > div > div:nth-child(1) {
    input {
      display: none;
    }
  }
}

.brandingTitle {
  color: #2962ff;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
  font-size: 11px;
}

.brandingTextContainer {
  display: flex;
  flex-direction: column;
}

.brandingTextButton {
  text-align: center;
  width: 70px !important;
  margin-top: 10px;
  margin-bottom: 10px;
}

.brandingPaleteContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 55%;
}

.paleteOptionContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 15px;
  width: 100px;
}

.paleteColor {
  min-width: 45px !important;
  min-height: 40px !important;
  border-radius: 2px;
  margin-right: 5px;
  margin-bottom: 5px;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.paletteContainer {
  position: absolute;
  background-color: #2a2d31;
  padding: 20px;
  border-radius: 5%;
  color: black;
  height: 300px;
}

.dot {
  background-color: white;
  height: 10px;
  width: 10px;
  align-self: flex-end;
  border-radius: 10px 2px 2px 2px;
}
.brandingButton {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 25px;
}

.colorEditIcon {
  height: 18px;
  width: 20px;
  align-self: center;
}

@media (max-width: 1250px) {
  .brandingContainer {
    justify-content: space-between;
  }
  .brandingPaleteContainer {
    width: 45%;
  }
  .paleteOptionContainer {
    width: 200px;
  }
  .paleteColor {
    margin-bottom: 10px;
  }
}

@media (max-width: 940px) {
  .brandingPaleteContainer {
    width: 60%;
  }
  .paleteOptionContainer {
    width: 100px;
  }
}

@media (max-width: 600px) {
  .brandingContainer {
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .paleteOptionContainer {
    width: 120px;
  }

  .brandingPaleteContainer {
    margin-top: 20px;
    width: 100%;
  }
}
