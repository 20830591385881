.MultiSelectArea {
  width: 100%;
  color: #768fff;
  font-size: 13px;
  background-color: #4b5158;
  margin-bottom: 15px;
  max-height: 424px;
  border-radius: 10px;
}

.selectButtons {
  margin-right: 11px;
  padding-left: 10px;
  border: none !important;
  text-align: left;
}
.selectButtonsActive {
  padding-left: 10px;
  margin-right: 11px;
  text-align: left;
}

.multiselectCheckbox {
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;
  .css-label {
    font-weight: 500;
    color: #fff !important;
  }
  input[type='checkbox'].css-checkbox + label.css-label {
    color: #fff !important;
  }
}
label.css-label {
  background-image: url('../../static/img/Icons/bitacheckboxblue.png');
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.TitleOption {
  color: white !important;
  font-weight: bold;
  padding-top: 8px;
  padding-bottom: 8px;
}

.disabled {
  color: #aaaaaa;
  pointer-events: none;
  border: 0 !important;
}
