.contentTab {
  display: flex;
}
.tabTitleButton {
  cursor: pointer;
  margin-right: 9px;
  height: 52px;
  width: 150px;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 13px;
  letter-spacing: 1.48px;
  line-height: 20px;
  text-align: center;
  border-radius: 3.15px;
}

.tabTitleButtonActive {
  background: #2962ff;
}
.tabTitleButtonInactive {
  border-radius: 4.05px;
  background-color: #4b5158;
}

.tabDisabled {
  opacity: 0.5;
  pointer-events: none;
}
