@import '../../sass/Variables.scss';

/// Inner Navbar
.navItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 3px 30px;
  color: inherit;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  background: inherit;
  height: 25px;

  &:hover {
    background: rgba($text-color-main, 0.2);
  }

  & svg {
    height: 14px;
    width: 14px;
    & path {
      fill: $text-color-main !important;
    }
  }
}

.arrow {
  width: 10px;
  height: 10px;
  transition: all 0.2s ease-in-out;
  position: absolute;
  left: 185px;
  top: 8.5px;
}

.arrowCollapsed {
  transform: rotate(0deg);
}

.arrowExpanded {
  transform: rotate(-90deg);
}

.selected {
  color: $text-color-tertiary !important;
  background: $text-color-main !important;
  // font-family: "Titillium Web SemiBold";
  cursor: none;
  font-weight: bold;
  border-right: 3px solid $text-color-tertiary;
  transition: all 0.2s;
  & svg {
    & path {
      fill: $text-color-tertiary !important;
    }
  }
  .banner {
    color: $input-text-color;
    background-color: $text-color-tertiary;
  }
}

.selectedItem {
  cursor: none !important;
  pointer-events: none !important;
}

.disabled {
  cursor: none !important;
  pointer-events: none !important;
}

.sideNavbarTitle {
  cursor: pointer;
  color: $text-color-main;
  font-size: 14.39px;
  letter-spacing: 1.74px;
  padding: 10px 30px;
}
.sideNavbarTitle:hover {
  background-color: rgba(255, 255, 255, 0.155);
}

.subtitleIndicator {
  border-radius: 50%;
  width: 9px;
  height: 9px;
  background: inherit;
  border: 2px solid $text-color-main;
}

.subtitleIndicator_selected {
  border-color: $text-color-tertiary;
}

.iconContent {
  color: inherit;
  line-height: 16px;
}

.textContent {
  font-size: 12px;
  letter-spacing: 0.25px;
  padding-left: 8px;
  font-weight: $font-weight-light;
}

.wrapper {
  transition: margin 0.5s;
}

.titleChildrenHidden {
  max-height: 0;
  transition: max-height 0.15s ease-out;
}
.titleChildrenShowing {
  max-height: 500px;
  transition: max-height 0.25s ease-in;
}

.popUpCloser {
  position: absolute;
  font-size: 30px;
  font-weight: bold;
  top: -10px;
  right: 0;
  font-size: 26px;
  cursor: pointer;
  padding: 20px;
  box-sizing: border-box;
}

.popUpAvantiIcon {
  margin-right: 20px;
}
.banner {
  margin-left: 10px;
  background: $input-text-color;
  color: $text-color-tertiary;
  font-weight: 500;
  font-size: 12px;
  border-radius: 6px;
  padding: 1px 10px 0px 10px;
  margin-top: -2px;
}

.banner {
  margin-left: 10px;
  background: $input-text-color;
  color: $text-color-tertiary;
  font-weight: 500;
  font-size: 12px;
  border-radius: 6px;
  padding: 1px 10px 0px 10px;
  margin-top: -2px;
}

@media (max-width: 768px) {
  .sideNavbarTitle {
    font-size: 12px;
    padding: 10px 15px;
  }
  .navItem {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 3px 20px;
  }

  .arrow {
    left: 165px;
  }
}
